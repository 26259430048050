import React, { useEffect, useState } from 'react'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { Footer } from './layout/Footer';
import { Header } from './layout/Header';
import { Home } from './layout/Home';
import { About } from './layout/About';
import { Products } from './layout/Products';
import { ProductDetail } from './layout/ProductDetail';
import { Contact } from './layout/Contact';
function App() {
  const [topmenu, setTopmenu] = useState([])

  useEffect(() => {
      callMenuPull()
  }, [])

  const callMenuPull = () => {
      //setLoading(true);
      let Rno = Math.floor(Math.random() * 1000) + 1 ;
      fetch('https://www.payalsinghal.com/manage/callapi.php?x='+Rno+'&req=MENU')
      .then(response => {
          return response.json()
      })
      .then(data => {
          let results = data
          setTopmenu(results.data)
      })
      .catch((error) =>{
          alert(error)
      })
      
  }


  return (
    <BrowserRouter>
      <div id="pg-home">
      <Header/>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <>
        {topmenu.length > 0 && (
            <>
            {topmenu.map(menu => (
                <>
                <Route path='/:cat' element={<Products />} />
                <Route path='/:cat/:prodid' element={<ProductDetail />}/>
                </>
            ))}
            </>
        )}
        </>
        
        <Route path={`/new-arrivals/:prodid`} element={<ProductDetail />}/>
        <Route path={`/sale/:prodid`} element={<ProductDetail />}/>
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<Home />} /> 
      </Routes>
      <Footer/>
      </div>
    </BrowserRouter>

  );
}

export default App;
