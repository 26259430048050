import React from 'react'
import { Link } from 'react-router-dom'

export const ProductDiv = (props) => {
  return (                
    <div className="col-sm-3 col-xs-12">
        <div className="thumbnail">
            <div className="image-thumb">
                <Link to={`/${props.cat}/${props.url}`} className="block">
                    <picture>
                    <source type="image/webp" srcset={props.imgwebp}/>
                    <source type="image/jpeg" srcset={props.imgjpg}/>
                    <img src={props.imgjpg} alt={props.name} title={props.name}/>
                    </picture>
                </Link>
            </div>
            <div className="caption">
                <h1 className="product-name">{props.name}</h1>
                <h2 className="product-price"><i className="fa fa-inr" aria-hidden="true"></i>{props.price}</h2>
                <div className="wishlist" id="wish{props.id}"><Link to="Javascript:addtowishbtn({props.id})"><i className="fa fa-heart-o"></i></Link></div>						
            </div>
        </div>
    </div>
  )
}
